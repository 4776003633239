import React from "react";
import "../styles/Footer.scss";
import {ReactComponent as LinkIcon} from "../assets/footer/link.svg"
import {ReactComponent as InstaIcon} from "../assets/footer/insta.svg"

export default function Footer() {
  return (
    <section className="footer" id="section_7">
      <footer>
        <div className="content">
          <p className="title">
            <a href="https://api2.hereweare.io/html/terms.html" className="textHighlight" target="_blank">HereWeAre TERMS OF USE</a>
            <a href="https://api2.hereweare.io/html/privacy_policy.html" target="_blank">PRIVACY POLICY</a>
          </p>
        </div>
        <div className="content">
          <p>
            <span>Address</span>
            <span>
              8, Seongnam-daero 331beon-gil, Bundang-gu, Seongnam-si,
              Gyeonggi-do, Republic of Korea
            </span>
          </p>
          <p>
            <span>Contact</span>
            <span>support@hereweare.io</span>
          </p>
        <div className="icons">
          <a href="https://www.instagram.com/hereweare_global/" target="_blank">
          <InstaIcon />
          </a>
          <a href="https://link.hereweare.io/AppStore" target="_blank">
          <LinkIcon />
          </a>
        </div>
        </div>
        <div className="content">
          <p>
            <span>ⓒ HWACOM CO., LTD. All rights reserved.</span>
          </p>
        </div>
        
      </footer>
    </section>
  );
}
