export type SlideDataType = {
  title: string[]; // 제목, highlight는 첫글자 ^H로 표시
  desc: string;
};

const SlideData: SlideDataType[] = [
  {
    title: ["LiveSpots Connections"],
    desc: `Join the most vibrant and meaningful
connections at a place where art comes to life.
Experience unprecedented interaction with HereWeAre.`,
  },
  //1 live location
  {
    title: ["Join the ", "^HLiveSpot", " at your location ", "^Hnow!"],
    desc: `Explore events and join easily`,
  },
  //2 active chat
  {
    title: ["^HConnect live ", " with people ", "^Honsite!"],
    desc: `Take part without the need to share any personal information`,
  },
  //3 emoji
  {
    title: ["Be a part of the ", "^Hcreative\ncommunity", " like never before"],
    desc: `Online artistic community \nfor live-event-goers`,
  },
  //4 notification
  {
    title: ["Get ", "^Hreal-time\nnotifications", " instantly"],
    desc: `Receive important updates and tips without missing out`,
  },
  //5 ordering
  {
    title: ["Easy, fast, and convenient ", "^Hon-site \nordering"],
    desc: `Order instantly with no lines, \nno waiting`,
  },
  //6 last/appdownload
  {
    title: ["Meet together now on HereWeAre!"],
    desc: `Join us in the world of art and be invited to\nan experience that will make the moment even more special.`,
  },
];

export default SlideData;
