import gsap from "gsap";

export default function sectionAnimation() {
  // section 0
  const tween0 = gsap.timeline({
    defaults: { duration: 0.8, ease: "power3.out" },
    repeat: -1,
  });

  tween0
    .from("#section_0 .bg", { xPercent: 0 }, "<")
    .to("#section_0 .bg", { xPercent: -100 }, "+=0.8")
    .to("#section_0 .bg", { xPercent: -200 }, "+=2")
    .to("#section_0 .bg", { xPercent: -300 }, "+=2");

  const tween1 = gsap.timeline({
    defaults: { duration: 0.75, ease: "power3.in" },
    repeat: -1,
  });

  tween1
    .to(
      "#image_1 img",
      {
        xPercent: -100,
      },
      "+=2"
    )
    .to(
      "#image_1 img",
      {
        xPercent: -200,
      },
      "+=2"
    );

  const tween2 = gsap.timeline({
    defaults: { duration: 2, ease: "power3.out" },
  });

  const tween3 = gsap.timeline({
    defaults: { duration: 0.75, ease: "power3.out" },
    repeat: -1,
  });

  tween3
    .from(".emoji_3 img:nth-child(1)", { opacity: 1 }, "+=1")
    .to(".emoji_3 img:nth-child(1)", { opacity: 0 }, "<")
    .to(".emoji_3 img:nth-child(2)", { opacity: 1 }, "<")
    .to(".emoji_3 img:nth-child(2)", { opacity: 0 }, "+=1")
    .to(".emoji_3 img:nth-child(3)", { opacity: 1 }, "<")
    .to(".emoji_3 img:nth-child(3)", { opacity: 0 }, "+=1")
    .to(".emoji_3 img:nth-child(4)", { opacity: 1 }, "<")
    .to(".emoji_3 img:nth-child(4)", { opacity: 0 }, "+=1")
    .to(".emoji_3 img:nth-child(1)", { opacity: 1 }, "<");

  const tween4 = gsap.timeline({
    defaults: { duration: 0.5, ease: "power1.inout" },
    repeat: 2,
  });
  tween4.to("#image_4 > img", {
    y: "-3rem",
    yoyo: true,
    repeat: 1,
  });

  const tween5 = gsap.timeline({
    defaults: { duration: 0.8, ease: "power3.out", delay: 1 },
  });

  tween5
    .from("#image_5 .img_0", { x: "0", opacity: 1 })
    .fromTo(
      "#image_5 .img_1",
      { x: "0", opacity: 0.5 },
      {
        x: "-20rem",
        opacity: 1,
      },
      "<"
    )
    .fromTo(
      "#image_5 .img_2",
      { x: "-40rem", y: "100%", opacity: 0.5 },
      {
        x: "-40rem",
        y: "0",
        opacity: 1,
      },
      "+=0.5"
    )
    .fromTo(
      "#image_5 .img_3",
      { x: "-40rem", y: "0", opacity: 0 },
      {
        x: "-60rem",
        y: "0",
        opacity: 1,
      },
      "+=0.5"
    );

  const tween6 = gsap.timeline({
    defaults: { duration: 0.2, ease: "power3.inout" },
  });
  tween6.to(
    "#section_6 button",
    { rotateZ: 3, y: "-1rem", repeat: 1, yoyo: true },
    "+=1.2"
  );
  tween6.to("#section_6 button", {
    rotateZ: -5,
    y: "0.5rem",
    repeat: 1,
    yoyo: true,
  });

  return [tween0, tween1, tween2, tween3, tween4, tween5, tween6];
}
