import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../styles/Header.scss";
import ScrollProgress from "./ScrollProgress";
import { ReactComponent as ExtIcon } from "../assets/header/icon_external_link.svg";
// import { ReactComponent as MobileMenuIcon } from "../assets/header/icon_menu.svg";
import { ReactComponent as GoogleDownloadIcon } from "../assets/header/google.svg";
import { ReactComponent as AppleDownloadIcon } from "../assets/header/apple.svg";
import gsap from "gsap";

const Header = () => {
  const [downloadMenuShowState, setDownloadMenuShowState] = useState(false);
  let mobileMenuRef = useRef<0 | 1>(0);
  let animation = useRef<boolean>(false);
  let mobileMenuAction: () => void = () => {};

  const tl = gsap.timeline({
    onComplete: () => {
      tl.kill();
    },
  });

  mobileMenuAction = () => {
    if (animation.current) return;
    animation.current = true;
    const newState = mobileMenuRef.current === 1 ? 0 : 1;
    const newsrc = newState === 1 ? "icon_close.svg" : "icon_menu.svg";
    tl.to(".mobileMenu", {
      yPercent: newState * 100,
      duration: 0.75,
      ease: "power1.inOut",
    })
      .to(".mobileMenuButton img", { duration: 0.3, opacity: 0.4 }, "<")
      .to(".mobileMenuButton img", {
        content: `url("/header/${newsrc}")`,
        opacity: 1,
        duration: 0.45,
      });
    mobileMenuRef.current = newState;
    animation.current = false;
  };

  useEffect(() => {
    tl.from(".mobileMenu", { yPercent: mobileMenuRef.current * 100 });
    tl.clear();
  });

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="logoContainer">
            <img className="logo icon"></img>
            <img className="logo text"></img>
          </div>
          <div className="menuContainer">
            <div className="webMenuButton">
              <button>
                <a href="/hereweare101" target="_blank">
                  HWA 101
                </a>
              </button>
              <button className="downloadButton">
                <div
                  className="download"
                  onClick={() =>
                    setDownloadMenuShowState(!downloadMenuShowState)
                  }
                >
                  <h3>Download</h3>
                  <ExtIcon />
                </div>
                <div
                  className={`downloadHiddenMenu ${
                    downloadMenuShowState ? "show" : ""
                  }`}
                >
                  <a href="https://play.google.com/store/apps/details?id=com.hsoft.hwa">
                    <GoogleDownloadIcon />
                  </a>
                  <a href="https://apps.apple.com/us/app/hereweare-livespot-connections/id1491869024?ls=1">
                    <AppleDownloadIcon />
                  </a>
                </div>
              </button>
            </div>
            <div className="mobile">
              <button className="mobileMenuButton" onClick={mobileMenuAction}>
                <img />
              </button>
            </div>
          </div>
        </div>
        <ScrollProgress />
      </header>
      <div className="mobileMenu">
        <ul>
          <li></li>
          <li>
            <a href="/">
              <h3>LiveSpots</h3>
            </a>
          </li>
          <li>
            <a href="/">
              <h3>Download</h3>
            </a>
            <div className="buttons">
              <a href="https://apps.apple.com/us/app/hereweare-livespot-connections/id1491869024?ls=1">
                <AppleDownloadIcon />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.hsoft.hwa">
                <GoogleDownloadIcon />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
