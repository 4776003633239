import React from "react";
import { useEffect } from "react";
import "../styles/ScrollProgress.scss";

export default function ScrollProgress() {
  return (
    <div className="scrollProgress">
      <div className="scrollProgressBar" />
    </div>
  );
}
