import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import sectionAnimation from "./sectionAnimation";

function setHeaderTransparent(isTransparent: boolean) {
  if (isTransparent) {
    document.querySelector("header")?.classList.add("transparent");
    document.querySelector("header")?.classList.remove("normal");
  } else {
    document.querySelector("header")?.classList.remove("transparent");
    document.querySelector("header")?.classList.add("normal");
  }
}

export default function initAnimation(
  refIndex: React.MutableRefObject<number>
) {
  gsap.registerPlugin(ScrollTrigger, Observer);

  const tweens = sectionAnimation();

  let sections = gsap.utils.toArray("section"),
    animating = false,
    currIndex = refIndex.current,
    totalIndex = sections.length - 1;

  const progressBar: HTMLDivElement | null =
    document.querySelector(".scrollProgressBar");

  function setScrollProgesss(index: number) {
    if (!progressBar) return;
    progressBar.style.width = `${(index / (totalIndex - 1)) * 100}%`;
  }

  gsap.set(sections[totalIndex] as GSAPTweenTarget, { yPercent: 100 });
  setHeaderTransparent(currIndex === 0);

  function turnDownPage() {
    let newIndex = currIndex + 1;
    if (newIndex > totalIndex) return;

    animating = true;
    let tl = gsap.timeline({
      defaults: { duration: 1, ease: "power1.inOut" },
      onComplete: () => {
        refIndex.current = currIndex = newIndex;
        animating = false;
      },
    });

    if (newIndex === totalIndex) {
      tl.fromTo(
        sections[newIndex] as GSAPTweenTarget,
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
        }
      );
    } else {
      tl.fromTo(
        sections[currIndex] as GSAPTweenTarget,
        {
          yPercent: 0,
        },
        {
          yPercent: -100,
        }
      );
      setScrollProgesss(newIndex);
      tweens[newIndex].delay(1.25).restart();
      tweens[currIndex]?.pause();
    }
    !currIndex && setHeaderTransparent(false);
  }

  function turnUpPage() {
    let newIndex = currIndex - 1;
    if (newIndex < 0) return;

    animating = true;
    let tl = gsap.timeline({
      defaults: { duration: 1, ease: "power1.inOut" },
      onComplete: () => {
        refIndex.current = currIndex = newIndex;
        animating = false;
      },
    });
    if (currIndex === totalIndex) {
      tl.fromTo(
        sections[currIndex] as GSAPTweenTarget,
        {
          yPercent: 0,
        },
        {
          yPercent: 100,
        }
      );
    } else {
      tl.fromTo(
        sections[newIndex] as GSAPTweenTarget,
        {
          yPercent: -100,
        },
        {
          yPercent: 0,
        }
      );
      setScrollProgesss(newIndex);
      tweens[newIndex].delay(1.25).restart();
      tweens[currIndex]?.pause();
    }
    !newIndex && setHeaderTransparent(true);
  }

  const changePage = (e: Observer) => {
    // wheel event
    if (e && e.deltaY && e.deltaY != 0) {
      e.deltaY > 0 ? turnUpPage() : turnDownPage();
    }

    // touch event
    if (animating || !e || !e.startY || !e.y) return;
    if (Math.abs(e.y - e.startY) < 10) {
      e.y > window.innerHeight / 2 ? turnDownPage() : turnUpPage();
    } else {
      e.y > e.startY ? turnUpPage() : turnDownPage();
    }
  };
  const ob = Observer.create({
    target: "main",
    type: "wheel,touch,pointer",
    wheelSpeed: -1,
    onClick: (e: Observer) => changePage(e),
    onDown: (e: Observer) => changePage(e),
    onUp: (e: Observer) => changePage(e),
    tolerance: 10,
  });

  const preventMobileRefresh = (event: TouchEvent) => {
    if (refIndex.current !== 0 && event.touches[0].clientY > 0) {
      event.preventDefault(); // 기본 새로고침 방지
    }
  };
  document.addEventListener("touchmove", preventMobileRefresh, {
    passive: false,
  });

  return () => {
    ob.kill();
    tweens.forEach((tw) => tw && tw.revert());
    document.removeEventListener("touchmove", preventMobileRefresh);
  };
}
