import React from "react";
import "../styles/SlideSection/SlideSection.scss";

export function TitleMapper({ content }: { content: string[] }) {
  return (
    <div className="title">
      {content &&
        content.length > 0 &&
        content.map((title, index) => {
          if (title.startsWith("^H")) {
            return (
              <span key={index} className="textHighlight">
                {title.slice(2)}
              </span>
            );
          } else {
            return <span key={index}>{title}</span>;
          }
        })}
    </div>
  );
}
