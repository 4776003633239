import React, { useEffect, useRef } from "react";
import Header from "./components/Header";
import SlideSection from "./components/SlideSection";
import SlideData from "./data/Slide";
import Footer from "./components/Footer";
import initAnimation from "./utils/initAnimation";

function App() {
  const currIndex = useRef(0);
  useEffect(() => initAnimation(currIndex), []);

  return (
    <div className="App">
      <Header />
      <main>
        {SlideData &&
          SlideData.length > 0 &&
          SlideData.map((slide, index) => {
            return <SlideSection key={index} content={slide} idx={index} />;
          })}
      </main>
      <Footer />
    </div>
  );
}

export default App;
