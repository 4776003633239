import React from "react";

// default :: 0, 1, 4
// need customization : 2, 3, 5, 6

function Animation0() {
  return (
    <div className="image" id="image_0">
      <img
        src="/slides/main/main visual.png"
        alt={`meeting people via hwa app`}
        className={`img_0`}
      />
    </div>
  );
}

function Animation1() {
  return (
    <div className="image" id="image_1">
      <div className="phoneMockup">
        <img
          src="/slides/1/mock0.png"
          alt={"app location visual"}
          className={`img_0`}
        />
        <img
          src="/slides/1/mock1.png"
          alt={"app location visual"}
          className={`img_1`}
        />
        <img
          src="/slides/1/mock0.png"
          alt={"app location visual"}
          className={`img_2`}
        />
      </div>
    </div>
  );
}

//bg:/slides/2/bg.png
function Animation2() {
  return (
    <div className="image" id={`image_2`}>
      <div className="phoneMockup">
        <img
          className="emojiList"
          src={"/slides/2/emoji_list.png"}
          alt="emojiList"
        />
        <div className="bottomContainer">
          <img className="bottom emoji1" src="/slides/2/emoji1.png"></img>
          <img className="bottom emoji2" src="/slides/2/emoji2.png"></img>
          <img className="bottom emoji3" src="/slides/2/emoji3.png"></img>
        </div>
      </div>
    </div>
  );
}

function Animation3() {
  return (
    <div className="image" id={`image_3`}>
      <ul>
        <li>
          <div className="emoji_3">
            <img src="/slides/3/emoji1.png" alt="emoji_1" />
            <img src="/slides/3/emoji2.png" alt="emoji_2" />
            <img src="/slides/3/emoji3.png" alt="emoji_3" />
            <img src="/slides/3/emoji4.png" alt="emoji_3" />
          </div>
        </li>
        <li>
          <div className="emoji_3">
            <img src="/slides/3/emoji2.png" alt="emoji_2" />
            <img src="/slides/3/emoji3.png" alt="emoji_3" />
            <img src="/slides/3/emoji4.png" alt="emoji_3" />
            <img src="/slides/3/emoji1.png" alt="emoji_1" />
          </div>
        </li>
        <li>
          <div className="emoji_3">
            <img src="/slides/3/emoji3.png" alt="emoji_3" />
            <img src="/slides/3/emoji4.png" alt="emoji_3" />
            <img src="/slides/3/emoji1.png" alt="emoji_1" />
            <img src="/slides/3/emoji2.png" alt="emoji_2" />
          </div>
        </li>
        <li>
          <div className="emoji_3">
            <img src="/slides/3/emoji4.png" alt="emoji_3" />
            <img src="/slides/3/emoji1.png" alt="emoji_1" />
            <img src="/slides/3/emoji2.png" alt="emoji_2" />
            <img src="/slides/3/emoji3.png" alt="emoji_3" />
          </div>
        </li>
      </ul>
    </div>
  );
}

function Animation4() {
  return (
    <div className="image" id="image_4">
      <img
        src="/slides/4/1.png"
        alt={`convenient on-site purchase visual`}
        className={`img_0`}
      />
    </div>
  );
}

function Animation5() {
  return (
    <div className="image" id={`image_5`}>
      <div className="phoneMockup">
        <img className="page page1" src="/slides/5/page1.png"></img>
        <img className="page page2" src="/slides/5/page2.png"></img>
        <img className="page page3" src="/slides/5/page3.png"></img>
        <img className="page page4" src="/slides/5/page4.png"></img>
      </div>
    </div>
  );
}

function Animation6() {
  return (
    <>
      <div className="image" id={`image_6`}>
        <a href="https://link.hereweare.io/AppStore" target="_blank">
          <button>App Download</button>
        </a>
      </div>
      <video autoPlay muted loop playsInline>
        <source src="/slides/6/bg.mp4" type="video/mp4" />
      </video>
    </>
  );
}

export default function Animation({ idx }: { idx: number }) {
  const _ = [
    Animation0,
    Animation1,
    Animation2,
    Animation3,
    Animation4,
    Animation5,
    Animation6,
  ];
  return _[idx]();
}
